import { environment } from '@ieEnv/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AuthDto,
  AuthResponseDto,
  RefreshTokenDto,
} from 'shared-general-libs/dto/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  private readonly apiUrl: string = environment.authHost;

  constructor(private http: HttpClient) {}

  login(payload: AuthDto): Observable<AuthResponseDto> {
    return this.http.post<AuthResponseDto>(
      `${this.apiUrl}/auth/login`,
      payload,
    );
  }

  refresh(payload: RefreshTokenDto): Observable<AuthResponseDto> {
    const headers = new HttpHeaders()
      .append('refresh-token', 'true')
      .append('Authorization', `Bearer ${payload.refreshToken}`);

    return this.http.post<AuthResponseDto>(
      `${this.apiUrl}/auth/refresh`,
      {},
      { headers },
    );
  }

  logout(payload: RefreshTokenDto): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/auth/logout`, payload);
  }
}
