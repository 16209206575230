import { Component, Input } from '@angular/core';

@Component({
  selector: 'ie-alert',
  standalone: true,
  imports: [],
  templateUrl: './ie-alert.component.html',
  styleUrl: './ie-alert.component.scss',
})
export class IeAlertComponent {
  @Input() type: 'success' | 'error' | 'warning' | 'info' = 'info';
  @Input() title: string = '';

  constructor() {}
}
