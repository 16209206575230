import { Injectable } from '@angular/core';
import {
  EXPIRATION_BUFFER_MS,
  REMEMBER_ME_KEY,
  STORAGE_KEY,
} from '@ieCore/consts/auth.consts';
import {
  AuthDto,
  AuthResponseDto,
  RefreshTokenDto,
} from 'shared-general-libs/dto/auth';
import { AuthRepository } from '@ieCore/repositories/auth.repository';
import {
  requestLoading,
  RequestLoadingType,
} from '@ieCore/helpers/request-loading.helper';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NavService } from '@ieCore/services/nav.service';
import { BehaviorSubject } from 'rxjs';
import * as UserActions from '@ieApp/store/user/user.actions';
import { Store } from '@ngrx/store';
import { UserType } from 'shared-general-libs/type/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private refreshTokenRequested = false;
  public refreshTokenSubject = new BehaviorSubject<boolean | null>(null);
  public storage = sessionStorage;

  constructor(
    private authRepository: AuthRepository,
    private store: Store,
    private navService: NavService,
    private router: Router,
  ) {
    const storage = localStorage.getItem(REMEMBER_ME_KEY);
    storage === 'true'
      ? (this.storage = localStorage)
      : (this.storage = sessionStorage);
  }

  isAuthenticated(): boolean {
    return !!this.storage.getItem(STORAGE_KEY);
  }

  storeAuthData(payload: AuthResponseDto, rememberMe: boolean) {
    localStorage.setItem(REMEMBER_ME_KEY, rememberMe.toString());

    if (rememberMe) {
      this.storage = localStorage;
    }

    this.storage.setItem(STORAGE_KEY, JSON.stringify(payload));
  }

  getAuthData(): AuthResponseDto {
    return JSON.parse(this.storage.getItem(STORAGE_KEY) || '{}');
  }

  getUserIdFromToken(token?: string): string {
    const authToken = token || this.getAuthData().accessToken;
    const obj = JSON.parse(atob(authToken.split('.')[1]));
    return obj.sub;
  }

  getUserTypeFromToken(token?: string): UserType {
    const authToken = token || this.getAuthData().accessToken;
    const obj = JSON.parse(atob(authToken!.split('.')[1]));
    return obj.type;
  }

  removeAuthData() {
    this.storage.removeItem(STORAGE_KEY);
    localStorage.removeItem(REMEMBER_ME_KEY);
  }

  tokenIsExpired() {
    const authData = this.getAuthData();
    return new Date().getTime() > authData.expiresIn - EXPIRATION_BUFFER_MS;
  }

  setRefreshTokenRequested() {
    this.refreshTokenRequested = true;
  }

  setNewTokenReady() {
    this.refreshTokenSubject.next(true);
    this.refreshTokenRequested = false;
    this.refreshTokenSubject.next(false);
  }

  isNewTokenRequested() {
    return this.refreshTokenRequested;
  }

  login(
    payload: AuthDto,
    rememberMe: boolean,
  ): RequestLoadingType<AuthResponseDto> {
    return requestLoading(
      this.authRepository.login(payload).pipe(
        tap((res) => {
          this.storeAuthData(res, rememberMe);

          this.store.dispatch(UserActions.load());

          const userType = this.getUserTypeFromToken();
          const route = this.navService.getRouteByUserType(userType);

          this.router
            .navigate([route])
            .then(() => console.log(`Navigated to ${userType} dashboard`));
        }),
        map((res) => res),
      ),
    );
  }

  refreshToken(refreshToken: string): RequestLoadingType<AuthResponseDto> {
    const storage = localStorage.getItem(REMEMBER_ME_KEY) === 'true';
    return requestLoading(
      this.authRepository.refresh({ refreshToken }).pipe(
        tap((res) => this.storeAuthData(res, storage)),
        map((res) => res),
      ),
    );
  }

  logout(payload: RefreshTokenDto): RequestLoadingType<void> {
    return requestLoading(
      this.authRepository.logout(payload).pipe(
        tap(() => {
          this.removeAuthData();

          this.store.dispatch(UserActions.clear());

          this.router
            .navigate(this.navService.to.login())
            .then(() => console.log('Navigated to login'));
        }),
      ),
    );
  }
}
