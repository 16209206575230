<div class="flex alert alert--{{ type }}">
  <div class="icon">
    @switch (type) {
      @case ('success') {
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167C21 10.8996 21 11.4234 21 11.9914C21 17.6294 16.761 20.3655 14.1014 21.5273C13.38 21.8424 13.0193 22 12 22C10.9807 22 10.62 21.8424 9.89856 21.5273C7.23896 20.3655 3 17.6294 3 11.9914C3 11.4234 3 10.8996 3 10.4167Z"
            stroke="#0F8359"
            stroke-width="1.5"
          />
          <path
            opacity="0.5"
            d="M9.5 12.4L10.9286 14L14.5 10"
            stroke="#0F8359"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
      @case ('error') {
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 6.94975C2 6.06722 2 5.62595 2.06935 5.25839C2.37464 3.64031 3.64031 2.37464 5.25839 2.06935C5.62595 2 6.06722 2 6.94975 2C7.33642 2 7.52976 2 7.71557 2.01738C8.51665 2.09229 9.27652 2.40704 9.89594 2.92051C10.0396 3.03961 10.1763 3.17633 10.4497 3.44975L11 4C11.8158 4.81578 12.2237 5.22367 12.7121 5.49543C12.9804 5.64471 13.2651 5.7626 13.5604 5.84678C14.0979 6 14.6747 6 15.8284 6H16.2021C18.8345 6 20.1506 6 21.0062 6.76946C21.0849 6.84024 21.1598 6.91514 21.2305 6.99383C22 7.84935 22 9.16554 22 11.7979V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V6.94975Z"
            stroke="#DC362E"
            stroke-width="1.5"
          />
          <path
            opacity="0.5"
            d="M10.5 15L13.5 12M13.5 15L10.5 12"
            stroke="#DC362E"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      }
      @case ('warning') {
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167C21 10.8996 21 11.4234 21 11.9914C21 17.6294 16.761 20.3655 14.1014 21.5273C13.38 21.8424 13.0193 22 12 22C10.9807 22 10.62 21.8424 9.89856 21.5273C7.23896 20.3655 3 17.6294 3 11.9914C3 11.4234 3 10.8996 3 10.4167Z"
            stroke="#E49F19"
            stroke-width="1.5"
          />
          <path
            opacity="0.5"
            d="M12 8V12"
            stroke="#E49F19"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            opacity="0.5"
            d="M12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z"
            fill="#E49F19"
          />
        </svg>
      }
      @case ('info') {
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="#5C88EF"
            stroke-width="1.5"
          />
          <g opacity="0.5">
            <path d="M12 17V11V17Z" fill="#5C88EF" />
            <path
              d="M12 17V11"
              stroke="#5C88EF"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </g>
          <path
            opacity="0.5"
            d="M12.25 8C12.25 7.86193 12.1381 7.75 12 7.75C11.8619 7.75 11.75 7.86193 11.75 8C11.75 8.13807 11.8619 8.25 12 8.25C12.1381 8.25 12.25 8.13807 12.25 8Z"
            fill="#5C88EF"
            stroke="#5C88EF"
            stroke-width="1.5"
          />
        </svg>
      }
    }
  </div>
  <div class="alert-container">
    @if (title) {
      <div class="alert-title mb-8 body-l cl-on-background">{{ title }}</div>
    }
    <div class="alert-desc body-m cl-on-background">
      <ng-content></ng-content>
    </div>
  </div>
</div>
