import { createAction, props } from '@ngrx/store';
import { UserResponseWithPermissionsDto } from 'shared-general-libs/dto/user';

export const load = createAction('[User] Load');

export const loadSuccess = createAction(
  '[User] Load Success',
  props<{ user: UserResponseWithPermissionsDto }>(),
);

export const loadFailure = createAction(
  '[User] Load Failure',
  props<{ error: string | null }>(),
);

export const clear = createAction('[User] clear');
